import { BadgePill, Button, Tooltip } from '@components/common';
import { useTranslation } from 'next-i18next';
import { useJobTalentActions } from '@hooks/features/useJobTalentActions';
import { HandThumbDownIcon as HandThumbDownIconOutlined, HandThumbUpIcon as HandThumbUpIconOutlined } from '@heroicons/react/24/outline';
import { CheckIcon, HandThumbDownIcon as HandThumbDownIconSolid, HandThumbUpIcon as HandThumbUpIconSolid } from '@heroicons/react/24/solid';
import { JobListType, JobPlacement } from '@type/job-params';
import { JobQuestion } from '@type/v1-api-types';


interface CardJobTalentActionsProps {
  job: ApiSchema<'JobItem'>,
  enableFloatingTooltip: boolean,
  requestId?: string,
  position?: number,
  listType?: JobListType,
}

export const CardJobTalentActions = (props: CardJobTalentActionsProps) => {
  // Destructure props
  const {
    job,
    enableFloatingTooltip,
    requestId,
    position,
    listType,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Job Talent Actions Hook
  // *****************************************
  const {
    isFavorite,
    isBlocked,
    isLoadingFavoritesAction,
    isLoadingBlockedAction,
    isApplying,
    addToFavorites,
    removeFromFavorites,
    addToBlocked,
    removeFromBlocked,
    applyToJob,
    hasApplied,
  } = useJobTalentActions({
    jobSlug: job.slug ?? '',
    jobId: job.id,
    jobTitle: job.title,
    company: job.companyName,
    likedProp: job.liked,
    dislikedProp: job.disliked,
    appliedProp: !!job.appliedAt,
    requestId,
    position,
    placement: JobPlacement.CARD,
    listType,
    withEasyApply: true,
    hasOwnApplyUrl: job.hasOwnApplyUrl,
    jobQuestions: job?.unansweredQuestions?.map(({ id, text }) => ({
      id: id?.toString(),
      question: text,
    })) as JobQuestion[],
  });

  // Helper classes
  // *****************************************
  const roundedButtonClass = '!p-2.5 !border-primary/20';
  const roundedButtonSpinnerClass = 'h-4.5 w-4.5 mx-0 mx-0 text-ink-medium';
  const roundedButtonIconClass = 'h-4.5 w-4.5';

  // Render component
  // *****************************************
  return (
    <div className="mb-3 flex items-center justify-center space-x-1.5">
      {hasApplied ? (
        /* Message displayed after fast apply */
        <BadgePill color="primary" styling="soft" size="xs" className="font-semibold">{ t('job.apply.react.toast.success') }</BadgePill>
      ) : (
        /* Actions: Block, Like & Fast apply */
        <>
          {/* Block / Dislike button */}
          <Tooltip
            tooltipText={isBlocked ? t('job.disliked') : t('job.dislike')}
            position="top"
            colorScheme="black"
            floating={enableFloatingTooltip}
            useFloatingPortal
          >
            <Button
              styling="outline"
              color={!isBlocked ? 'primary' : 'error'}
              rounding="full"
              size="sm"
              isLoading={isLoadingBlockedAction}
              spinnerClass={roundedButtonSpinnerClass}
              className={`${roundedButtonClass} hover:!bg-surface hover:!text-error`}
              onClick={isBlocked ? removeFromBlocked : addToBlocked}
            >
              {!isLoadingBlockedAction && (
                isBlocked
                  ? <HandThumbDownIconSolid className={roundedButtonIconClass} />
                  : <HandThumbDownIconOutlined className={roundedButtonIconClass} />
              )}
            </Button>
          </Tooltip>

          {/* Favorites / Like button */}
          <Tooltip
            tooltipText={isFavorite ? t('global.liked') : t('global.like')}
            position="top"
            colorScheme="black"
            floating={enableFloatingTooltip}
            useFloatingPortal
          >
            <Button
              styling="outline"
              color={!isFavorite ? 'primary' : 'warning'}
              rounding="full"
              size="sm"
              isLoading={isLoadingFavoritesAction}
              spinnerClass={roundedButtonSpinnerClass}
              className={`${roundedButtonClass} hover:!bg-surface hover:!text-warning`}
              onClick={isFavorite ? removeFromFavorites : addToFavorites}
            >
              {!isLoadingFavoritesAction && (
                isFavorite
                  ? <HandThumbUpIconSolid className={roundedButtonIconClass} />
                  : <HandThumbUpIconOutlined className={roundedButtonIconClass} />
              )}
            </Button>
          </Tooltip>

          {/* Fast apply button */}
          <Button
            styling="soft"
            color="secondary-light"
            rounding="full"
            size="sm"
            className="flex-1 overflow-hidden"
            isLoading={isApplying}
            spinnerClass="w-5 h-5 -ml-1 mr-2"
            onClick={applyToJob}
          >
            {!isApplying && <CheckIcon className="-ml-1 mr-2 size-5 shrink-0" />}
            <span className="truncate">
              { t('global.bnt.fast.apply') }
            </span>
          </Button>
        </>
      )}
    </div>
  );
};
