import Link from 'next/link';
import { useExtendedSearchParams } from '@hooks/next-routing-wrappers';
import { useTranslation } from 'next-i18next';
import {
  Anchor, Avatar, Button, BadgePill, Card, ErrorBoundary, RatingScore, Tooltip,
} from '@components/common';

import {
  BoltIcon, ArrowRightIcon, CheckIcon, ArrowPathIcon,
} from '@heroicons/react/20/solid';
import {
  EyeIcon, ClipboardDocumentListIcon, MapPinIcon, CurrencyEuroIcon,
} from '@heroicons/react/24/outline';
import { convertToLocalizedDate } from '@utils/date-time/date-time';
import { CardJobTalentActions } from '@components/templates/job/Card/CardJobTalentActions';
import {
  Dispatch, forwardRef, memo, useContext, useMemo, useState, SetStateAction, MouseEvent,
} from 'react';
import { Input, RecordInterface } from '@components/common/Forms';
import { CardAnimations } from '@type/card';
import { JobTrackingContext } from '@components/contexts/JobTrackingContext';
import { getI18nLinkHref } from '@services/i18nPaths';
import NiceModal from '@ebay/nice-modal-react';
import { clsx } from 'clsx';
import { buildJobDetailUrlParams } from '@utils/job/buildJobDetailUrlParams';
import { InterViewQuestionsModal } from '@components/templates/job/apply/InterviewQuestionsModal';
import { JobQuestion } from '@type/v1-api-types';

// Interface
// ******************************************
export interface CardJobTalentBulkApplyFormProps {
  record: RecordInterface,
  name: string,
  setSelectAll?: Dispatch<SetStateAction<boolean | undefined>>,
}

export interface CardJobTalentProps {
  job: ApiSchema<'JobItem'>,
  locale: Locale,
  animation?: CardAnimations,
  className?: string,
  openInModal?: boolean,
  bulkApply?: CardJobTalentBulkApplyFormProps,
  position?: number,
  beforeModalOpen?: () => void;
  isTopJob?: boolean,
}

/**
 * @description CardJobTalent component.
 *
 * - job: *ApiSchema<'JobItem'>* - The job item. Required.
 * - locale - *Locale* - The current app locale.
 * - animation: *CardAnimations* - The animation for the card. Optional. Default is 'riseUp'.
 * - className: *string* - Additional classes for the card. Optional.
 * - openInModal: *boolean* - If true, the job will open in a modal. Optional. Default is true.
 * - bulkApply: *object* - The object containing the record function and the job slug for bulk apply. Optional. Default is undefined.
 * - position: *number* - The index position of the job card in the list. Used for tracking. Optional.
 * - beforeModalOpen: *function* - Callback function that gets executed before modal open.
 * - isTopJob: *boolean* - If true, the job is a top job. Optional.
 *
 * BulkApply object:
 * - record: *RecordInterface* - The record function from useBjForm. Required.
 * - name: *string* - The name of the form group. Required.
 * - setSelectAll: *Dispatch<SetStateAction<boolean | undefined>>* - The function to set the 'select all' checkbox. Optional. Default is undefined.
 */
export const CardJobTalent = forwardRef<HTMLDivElement, CardJobTalentProps>((props, ref) => {
  // Props
  const {
    job,
    locale,
    animation = 'riseUp',
    className = '',
    position,
    openInModal = false,
    bulkApply,
    beforeModalOpen,
    isTopJob = false,
  } = props;

  const {
    record,
    name,
    setSelectAll,
  } = bulkApply || {};

  // Translation
  const { t } = useTranslation('common');

  const { searchParams } = useExtendedSearchParams();

  // Common classes
  // *****************************************
  const cardClassesBase = {
    common: 'relative rounded-md bg-surface border border-y-4 overflow-hidden group',
    highlighted: job.highlighted ? 'border-info' : 'border-transparent',
    checked: 'has-[:checked]:ring has-[:checked]:ring-secondary/20 has-[:checked]:after:block has-[:checked]:after:rounded-md has-[:checked]:after:border has-[:checked]:after:border-secondary has-[:checked]:after:absolute has-[:checked]:after:-inset-x-px has-[:checked]:after:-inset-y-1',
    custom: className || '',
  };
  const cardClasses = Object.values(cardClassesBase).join(' ');
  const applicantBadgeClasses = 'font-medium !bg-transparent !px-0';
  const appliedAtClasses = 'flex gap-x-2 text-xs leading-5 !px-0';

  // Helper variables
  // *****************************************
  const jobSlug = job.slug;
  const jobLocation = job.locations && job.locations.length > 0 ? job.locations[0] : null;
  const allowFastApply = isTopJob ? true : (job.active && !job.appliedAt);

  const needToCompleteInterview = (job.appliedAt && job?.unansweredQuestions && job?.unansweredQuestions?.length > 0);

  let interviewQuestions = [] as JobQuestion[];
  if (needToCompleteInterview) {
    interviewQuestions = job?.unansweredQuestions?.map(({ id, text }) => ({
      id: id?.toString(),
      question: text,
    })) as JobQuestion[];
  }

  // Conditions for showing badges
  // *****************************************
  const showBadge = {
    premium: job.premium,
    responsive: !job.premium && job.responsive,
    firstApplicant: job.active && job.applications === 0,
    amongFirstApplicants: job.active && !job.appliedAt && typeof job.applications === 'number' && job.applications > 0 && job.applications < 6,
    autoApply: job.autoApply,
  };

  // Conditions for locations
  // *****************************************
  const showLocation = {
    first: job.locations && job.locations.length > 0,
    more: job.locations && job.locations.length > 1,
  };

  // Conditions for salary label
  // *****************************************
  const salaryLabel = {
    default: job.salary,
    estimate: !job.salary && job.estimatedSalary,
  };

  // Get tracking context data
  // **************************************
  const {
    requestId,
    listType,
    searchId,
  } = useContext(JobTrackingContext) || {};

  // Helpers for job url.
  // ************************************
  // Default job url. It is the localized url.
  const defaultJobUrl = useMemo(
    () => getI18nLinkHref(`/job/${jobSlug}`, locale),
    [jobSlug, locale],
  );

  // State for job url. It will be updated with query params.
  // **************************************
  const [jobUrl, setJobUrl] = useState<string>(defaultJobUrl);

  // Build search url params to be attached to the job url
  // **************************************
  const urlSearchParams = buildJobDetailUrlParams({
    requestId,
    position,
    searchId,
  });

  // Update job url. Append query params to the default job url.
  // *****************************************
  const updateJobUrl = () => {
    setJobUrl(`${defaultJobUrl}${urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : ''}`);

    if (openInModal && beforeModalOpen) {
      beforeModalOpen();
    }
  };

  // Toggle floating tooltip state, on mouseenter / mouseleave
  // *****************************************
  const [enableFloatingTooltip, setEnableFloatingTooltip] = useState(false);

  const translation = Array.isArray(job.translations) && job.translations.length > 0
    ? job.translations.find(
      (tr) => tr.locale === locale && tr.field === 'title',
    )
    : null;

  const jobTitle = translation?.content?.trim() ? translation.content : job.title;

  // Open job detail modal
  // *****************************************
  const handleJobOpening = (e: MouseEvent<HTMLAnchorElement>) => {
    // Check if Ctrl (Windows/Linux) or Meta (Mac) key is pressed
    if (e.ctrlKey || e.metaKey) {
      // Allow the default behavior to open in a new tab
      return;
    }

    // Prevent default action for regular clicks
    e.preventDefault();

    // Build the params object from existing searchParams
    const params = new URLSearchParams(searchParams?.toString());

    // Set the selectedJobSlug parameter
    params.set('selectedJobSlug', jobSlug as string);

    // Construct the new URL with proper query parameters
    const newUrl = `${jobUrl}${jobUrl.includes('?') ? '&' : '?'}${params.toString()}`;

    // Update the history with the new URL
    window.history.replaceState(null, '', newUrl);
  };

  // Render component
  // *****************************************
  return (
    <Card
      ref={ref}
      animation={animation}
      className={cardClasses}
      onMouseEnter={() => setEnableFloatingTooltip(true)}
      onMouseLeave={() => setEnableFloatingTooltip(false)}
    >
      <ErrorBoundary fallback={<div className="flex items-center justify-center p-3 text-error">Error loading job data.</div>}>

        {/*
          Full-card link to the job details page.
          Uses an anchor tag for SEO when opened in a modal.
          Defaults to the Next.js Link component otherwise.
        */}
        {openInModal ? (
          <a
            href={jobUrl}
            onMouseDown={updateJobUrl}
            onClick={handleJobOpening}
            className="absolute inset-0 z-1"
            aria-label={job.title}
          >
            <span className="hidden">{job.title}</span>
          </a>
        ) : (
          <Link
            href={jobUrl}
            onMouseDown={updateJobUrl}
            className="absolute inset-0 z-1"
            aria-label={job.title}
          >
            <span className="hidden">{job.title}</span>
          </Link>
        )}

        {/* Bulk Apply checkbox; uncheck 'select all' checkbox on click */
          record && name && (
            <label
              htmlFor={`${name}_${jobSlug}`}
              aria-label={t('global.default.select.option')}
              title={t('global.default.select.option')}
              className="absolute right-0 top-0 z-2 cursor-copy p-1"
            >
              <Input
                defaultValue={jobSlug}
                type="checkbox"
                className="flex size-6 cursor-copy text-secondary ring-3 ring-surface checked:bg-secondary"
                {...record(name, {
                  id: `${name}_${jobSlug}`,
                  valueAsArray: true,
                })}
                onClick={() => {
                  if (setSelectAll) setSelectAll(undefined);
                }}
              />
            </label>
          )
        }

        {/* CARD BODY */}
        <Card.Body className="grid grid-cols-10 !px-3 !pb-0 !pt-5">
          <div className="col-span-6">
            {/* Top actions */}
            {(needToCompleteInterview || showBadge.autoApply) && (
              <div className="-mt-2 mb-4 flex items-center">
                {/* Complete interview  */}
                {needToCompleteInterview && (
                  <Tooltip
                    tooltipText={t('job.list.incomplete_interview')}
                    position="right"
                    colorScheme="black"
                    floating={enableFloatingTooltip}
                    className="!z-3"
                  >
                    <Button
                      rounding="full"
                      color="light"
                      className="relative z-2 mr-1 !bg-surface !p-2 !text-ink-medium hover:!bg-surface-100 hover:!text-ink hover:!shadow-none"
                      onClick={() => {
                        void NiceModal.show(InterViewQuestionsModal, {
                          jobQuestion: interviewQuestions,
                          locale,
                          onSaveSuccess: () => {},
                          jobSlug,
                        });
                      }}
                    >
                      <ClipboardDocumentListIcon className="size-5" />
                    </Button>
                  </Tooltip>
                )}

                {/* Auto-apply badge */}
                { showBadge.autoApply && (
                  <BadgePill styling="soft" size="pico" color="pink" className="ml-auto font-semibold uppercase">
                    <ArrowPathIcon className="size-4 fill-current" />
                    {t('job.card.autoApply')}
                  </BadgePill>
                )}
              </div>
            )}

            {/* Job title - max 2 lines */}
            <h2 className="line-clamp-2 text-base font-bold leading-6">{jobTitle}</h2>

            {/* Badges 'be first / among first' applicants OR 'Applied at' info - put them outside Card.Body to be properly aligned */}
            <div className="mt-2">
              {/* Badge first applicant */}
              { showBadge.firstApplicant && (
                <BadgePill color="secondary" styling="soft" className={applicantBadgeClasses}>
                  <BoltIcon className="size-3 fill-current" />
                  {t('first.applicant.badge')}
                </BadgePill>
              )}
              {/* Badge among first applicants */}
              { showBadge.amongFirstApplicants && (
                <BadgePill color="primary-light" styling="soft" className={applicantBadgeClasses}>
                  <ArrowRightIcon className="size-3 fill-current" />
                  {t('less.applicants.badge')}
                </BadgePill>
              )}
              {/* Applied at */}
              {job.appliedAt && (
                <>
                  <div className={clsx(appliedAtClasses, 'text-ink-medium')}>
                    <CheckIcon className="size-4" />
                    {`${t('global.label.applied_at')} ${convertToLocalizedDate(job.appliedAt, locale)}`}
                  </div>
                  <div className={clsx(appliedAtClasses, job.applicationViewedAt ? 'text-secondary-light' : 'text-ink-medium')}>
                    <EyeIcon className="size-4" />
                    {
                      job.applicationViewedAt
                        ? `${t('job.card.viewed_at')} ${convertToLocalizedDate(job.applicationViewedAt, locale)}`
                        : t('job.card.unviewed')
                    }
                  </div>
                </>
              )}
            </div>

            {/* Employer name */}
            <div className="mt-2 line-clamp-1 w-full text-sm text-ink-medium">
              {job.companyName}
            </div>
          </div>
          <div className="col-span-4">
            {/* Employer logo */}
            <div className="relative flex h-15 items-center justify-center">
              <Avatar
                imgPath={job.companyLogo}
                imgFolder="employer_logo"
                extractImageName
                name={job.companyName}
                size="md"
                aspectRatio="square-fit"
                rounding="none"
                id={(job.companyName || job.slug || '').charCodeAt(0)}
              />
            </div>
            {/* Employer rating */}
            { (job.rating !== undefined && job?.rating > 0) && (
              <div className="mt-2 flex justify-center sm:h-5">
                <RatingScore score={job.rating} showScore={false} className="size-4 text-warning" />
              </div>
            )}
            {/* Badges premium / responsive */}
            <div className="relative mt-2 text-center sm:h-7">
              {/* Badge premium talent */}
              { showBadge.premium && (
                <BadgePill size="pico" color="primary" styling="soft" className="truncate">
                  {t('cv.detail.premium')}
                </BadgePill>
              )}
              {/* Badge employer responsive */}
              { showBadge.responsive && (
                <BadgePill size="pico" color="primary" styling="soft" className="truncate">
                  {t('employer.card.responsive')}
                </BadgePill>
              )}
            </div>
          </div>
        </Card.Body>


        {/* CARD FOOTER */}
        <Card.Footer className={clsx('relative z-2 mt-3 bg-surface px-3', { 'can-hover:transition-all can-hover:duration-200 can-hover:ease-in-out can-hover:h-13 can-hover:group-hover:-translate-y-13': allowFastApply })}>
          <div className="grid h-13">
            {/* Job locations; we only display the first location */}
            <div className="text-left text-sm text-ink-medium">
              {(job.salary || job.estimatedSalary) && (
              <div className="flex items-center tracking-wider">
                <CurrencyEuroIcon className="mr-1 h-4" />
                {`${job.salary || job.estimatedSalary}`}
                { salaryLabel.estimate && ` (${t('salary.estimate')})` }
              </div>
              )}

              <div className="flex items-center tracking-wider">
                {showLocation.first && <MapPinIcon className="mr-1 h-4" />}
                { // show first location
                  showLocation.first && jobLocation && (
                    <Anchor
                      symfonyRoute
                      href="app_job_city"
                      symfonyParams={{ location: jobLocation.slug }}
                      styling="none"
                      className="relative z-2 hover:underline"
                    >
                      {jobLocation.name}
                    </Anchor>
                  )
                }
                { showLocation.more && <span className="inline-block">, ...</span> }
              </div>
            </div>
          </div>

          {/* Job actions */}
          {allowFastApply && (
            <CardJobTalentActions
              job={job}
              enableFloatingTooltip={enableFloatingTooltip}
              requestId={requestId}
              position={position}
              listType={listType}
            />
          )}
        </Card.Footer>

      </ErrorBoundary>
    </Card>
  );
});

/**
 * @description Memoized CardJobTalent component. To be used in the infinite lists.
 */
export const CardJobTalentMemo = memo(CardJobTalent);
